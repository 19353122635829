import { createContext, FunctionComponent, ReactNode, useContext, useState } from 'react';
import { INavState } from '~/shell/navigation/navigation.d';

interface INavigationState {
    navState: INavState;
    setNavState: (navState: INavState) => void;
}

const NavigationContext = createContext<INavigationState>({} as INavigationState);

// Shared context
export const useNavigationLayout = () => useContext(NavigationContext);
export const NavigationProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
    const navigation = useProvideNavigation();

    return <NavigationContext.Provider value={navigation}>{children}</NavigationContext.Provider>;
};

const initialData: INavState = {
    isOpen: false,
    searchIsOpen: false,
    searchHasItems: false,
    profileIsOpen: false,
    settingsIsOpen: false,
    level: 0,
    levels: {},
};

const useProvideNavigation = () => {
    const [state, setState] = useState(initialData);

    const setNavState = (newState: INavState) =>
        setState({
            ...state,
            ...newState,
        });

    return {
        navState: state,
        setNavState,
    };
};

export default useNavigationLayout;
