import { Products as ProductTypes } from '~/models/products.d';

/**
 * Extracts the last segment of a path.
 */
export function getLastUrlSegment(urlMapping: string) {
    const result = urlMapping.split('/');

    return result[result.length - 1];
}

/**
 * Matches first slug of the url to the two catalog types, and falls back to universal
 */
export function getCatalogType(url?: string, urlMapping?: string): ProductTypes.CatalogType {
    if (!url || !urlMapping) {
        console.log('Missing url or url mapping. Could not resolve catalog type.');

        return ProductTypes.CatalogType.UniversalCatalog;
    }

    const [, slug] = new RegExp(/^\/(?:[a-z]{2}-[a-z]{2}\/)?([a-z0-9-_]+)\//i).exec(url) ?? [];

    if (slug && slug === getLastUrlSegment(urlMapping)) {
        return ProductTypes.CatalogType.SparePart;
    }

    return ProductTypes.CatalogType.UniversalCatalog;
}
