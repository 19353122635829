import { Products as ProductTypes } from '~/models/products';
import { GA4 } from '~/libs/ga4/types/ga4';
import { addOptions, ConverterOpts, getDictionaryValue } from '~/libs/ga4/utils';

export function convertFavoriteProduct(favorite: ProductTypes.IFavoriteProduct, opts: ConverterOpts): GA4.TrackingProduct {
    const { oeMatch } = opts;

    const obj: GA4.TrackingProduct = {
        item_name: favorite?.title?.toLowerCase() as string,
        item_id: favorite?.itemId?.toLowerCase() as string,
        item_brand: favorite?.brand?.name?.toLowerCase() as string,
        currency: opts?.currency,
        price: opts?.price,
        item_category: favorite?.categoryName?.toLowerCase(),
        item_category2: favorite?.categoryParent?.toLowerCase(),
        item_category3: '',
        item_category4: oeMatch ? 'yes' : 'no', // oe_match
        item_category5: '', // stock_status
    };

    if (typeof favorite?.itemId !== 'undefined' && typeof opts?.productId !== 'undefined') {
        const productItemNo = getDictionaryValue(favorite.itemId, 'productItemNo', opts.productId);
        if (typeof productItemNo !== 'undefined') {
            obj.product_id = productItemNo;
        }
    }

    addOptions(obj, opts);

    return obj;
}
