/**
 * The key is the string to be replaced. The value is the replacement.
 */
type ReplacementTokenParam = { [key: string]: string };

/**
 * Useful for replacing tokens in URL with dynamic values.
 *
 * Usage:
 *
 * ```
 * const replacedUrl = replaceUrlTokens('https://example.com?foo=##token##', { '##token##': 'replace with this' });
 * ```
 */
export function replaceUrlTokens(url: string, tokens: ReplacementTokenParam) {
    let replacedUrl = url;

    for (const [token, replacement] of Object.entries(tokens)) {
        replacedUrl = replacedUrl.replace(token, encodeURIComponent(replacement));
    }

    return replacedUrl;
}
