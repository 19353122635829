import { Products as ProductTypes } from '~/models/products';
import { addOptions, getDictionaryValue, ConverterOpts } from '~/libs/ga4/utils';
import { GA4 } from '~/libs/ga4/types/ga4';

export function convertVariantLight(variant: ProductTypes.IVariantLight, opts: ConverterOpts) {
    const { itemCategory = '', itemCategory2 = '', itemCategory3 = '' } = opts;

    const obj: GA4.TrackingProduct = {
        item_name: variant?.title?.toLowerCase() as string,
        item_id: variant?.ftzCode?.toLowerCase() as string,
        item_brand: variant?.brand?.name?.toLowerCase() as string,
        currency: opts?.currency,
        price: opts?.price,
        item_category: itemCategory.toLowerCase(),
        item_category2: itemCategory2.toLowerCase(),
        item_category3: itemCategory3.toLowerCase(),
        item_category4: variant?.oeMatch ? 'yes' : 'no', // oe_match
        item_category5: '', // stock_status
        product_id: opts?.productId as string,
    };

    if (typeof variant?.ftzCode !== 'undefined' && typeof variant?.productItemNo !== 'undefined') {
        const productItemNo = getDictionaryValue(variant.ftzCode, 'productItemNo', variant.productItemNo);
        if (typeof productItemNo !== 'undefined') {
            obj.product_id = productItemNo;
        }
    }

    addOptions(obj, opts);

    return obj;
}
