import { createContext, useContext, useReducer } from 'react';
import { CmsData } from '~/models/cms-data.d';
import { NotificationLevel, INotification } from '~/shell/notifications/notifications.d';
import { Products as ProductTypes } from '~/models/products.d';

type SelectedDepartment = {
    id: number;
    title: string;
};

export type ErrorReportPayload = {
    itemId?: string | number;
    licensePlateNumber?: string;
    carId?: string;
    productVariants?: ProductTypes.IVariant[];
    product?: ProductTypes.IProduct;
};

type Category = {
    name: string;
    id: number | string;
};

type Toast = {
    text: string;
    subText?: string;
    notificationLevel: NotificationLevel;
    id?: string;
};

export interface ISwitchCustomerOrBasket {
    customerId?: string;
    basketId?: string;
    callBack?: () => void;
}

export enum LayoutActionTypes {
    SetLoading,
    SetIsRedirecting,
    SetVehicle,
    SetExpertMode,
    OpenErrorReport,
    SetCategory,
    AddToast,
    RemoveToast,
    SetLicensePlateCountry,
    SetUpdatingBasketContent,
    SetSelectedDepartment,
    Impersonate,
    SetIsCheckoutPage,
    setOperationalNotifications,
    SetImpersonated,
}

type Action =
    | { type: LayoutActionTypes.SetLoading; payload: boolean }
    | { type: LayoutActionTypes.SetIsRedirecting; payload: boolean }
    | { type: LayoutActionTypes.SetUpdatingBasketContent; payload: boolean }
    | { type: LayoutActionTypes.SetVehicle; payload: CmsData.IBaseVehicleInfo | null }
    | { type: LayoutActionTypes.SetExpertMode; payload: boolean }
    | { type: LayoutActionTypes.OpenErrorReport; payload: ErrorReportPayload | null }
    | { type: LayoutActionTypes.SetCategory; payload: Category }
    | { type: LayoutActionTypes.AddToast; payload: Toast }
    | { type: LayoutActionTypes.RemoveToast; payload: string }
    | { type: LayoutActionTypes.SetLicensePlateCountry; payload: string | null }
    | { type: LayoutActionTypes.SetSelectedDepartment; payload: SelectedDepartment | null }
    | { type: LayoutActionTypes.Impersonate; payload: ISwitchCustomerOrBasket | null }
    | { type: LayoutActionTypes.SetIsCheckoutPage; payload: boolean }
    | { type: LayoutActionTypes.setOperationalNotifications; payload: boolean }
    | { type: LayoutActionTypes.SetImpersonated; payload: boolean };

type State = {
    loading: boolean | null;
    isRedirecting: boolean | null;
    updatingBasketContent: boolean | null;
    vehicle: CmsData.IBaseVehicleInfo | null;
    expertMode: boolean | null;
    errorReport: ErrorReportPayload | null;
    category: Category | null;
    notifications: INotification[];
    licensePlateCountry: string | null;
    selectedDepartment: SelectedDepartment | null;
    customerOrBasketTransfer: ISwitchCustomerOrBasket | null;
    isCheckout: boolean | null;
    showOperationalNotifications: boolean | null;
    isImpersonated: boolean | null;
};

type Dispatch = (action: Action) => void;
type LayoutProviderProps = { children: React.ReactNode };

const LayoutStateContext = createContext<State | undefined>(undefined);
const LayoutProviderContext = createContext<Dispatch | undefined>(undefined);

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case LayoutActionTypes.SetLoading:
            return {
                ...state,
                loading: action.payload,
            };

        case LayoutActionTypes.SetIsRedirecting:
            return {
                ...state,
                isRedirecting: action.payload,
            };

        case LayoutActionTypes.SetUpdatingBasketContent:
            return {
                ...state,
                updatingBasketContent: action.payload,
            };

        case LayoutActionTypes.SetVehicle:
            return {
                ...state,
                vehicle: action.payload,
            };

        case LayoutActionTypes.SetExpertMode:
            return {
                ...state,
                expertMode: action.payload,
            };

        case LayoutActionTypes.OpenErrorReport:
            return {
                ...state,
                errorReport: action.payload,
            };

        case LayoutActionTypes.SetCategory:
            return {
                ...state,
                category: action.payload,
            };

        case LayoutActionTypes.AddToast:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        ...action.payload,
                        timeStamp: Date.now().toString(),
                    },
                ].slice(0, 3), // max 3 items
            };

        case LayoutActionTypes.RemoveToast:
            return {
                ...state,
                notifications: state.notifications.filter((n) => n.timeStamp !== action.payload),
            };

        case LayoutActionTypes.SetLicensePlateCountry:
            return {
                ...state,
                licensePlateCountry: action.payload,
            };

        case LayoutActionTypes.SetSelectedDepartment:
            return {
                ...state,
                selectedDepartment: action.payload,
            };

        case LayoutActionTypes.Impersonate:
            return {
                ...state,
                customerOrBasketTransfer: action.payload,
            };

        case LayoutActionTypes.SetIsCheckoutPage:
            return {
                ...state,
                isCheckout: action.payload,
            };

        case LayoutActionTypes.setOperationalNotifications:
            return {
                ...state,
                showOperationalNotifications: action.payload,
            };

        case LayoutActionTypes.SetImpersonated:
            return {
                ...state,
                isImpersonated: action.payload,
            };

        default:
            throw new Error('Unknown action');
    }
};

export const LayoutProvider = ({ children }: LayoutProviderProps) => {
    const [state, dispatch] = useReducer(reducer, {
        loading: false,
        isRedirecting: false,
        updatingBasketContent: false,
        vehicle: null,
        expertMode: false,
        errorReport: null,
        category: null,
        notifications: [],
        licensePlateCountry: null,
        selectedDepartment: null,
        customerOrBasketTransfer: null,
        isCheckout: false,
        showOperationalNotifications: true,
        isImpersonated: false,
    });

    return (
        <LayoutStateContext.Provider value={state}>
            <LayoutProviderContext.Provider value={dispatch}>{children}</LayoutProviderContext.Provider>
        </LayoutStateContext.Provider>
    );
};

export const useLayoutState = () => {
    const context = useContext(LayoutStateContext);

    if (!context) {
        throw new Error('useLayoutState must be used within a LayoutProvider');
    }

    return context;
};

export const useLayoutDispatch = () => {
    const context = useContext(LayoutProviderContext);

    if (!context) {
        throw new Error('useLayoutDispatch must be used within a LayoutProvider');
    }

    return context;
};

export const addSuccessToast = (text: string, subText?: string): Action => ({
    type: LayoutActionTypes.AddToast,
    payload: {
        text,
        subText,
        notificationLevel: NotificationLevel.SUCCESS,
    },
});

export const addErrorToast = (text: string, subText?: string, id?: string): Action => ({
    type: LayoutActionTypes.AddToast,
    payload: {
        text,
        subText,
        notificationLevel: NotificationLevel.ERROR,
        id,
    },
});

export const addInfoToast = (text: string, subText?: string): Action => ({
    type: LayoutActionTypes.AddToast,
    payload: {
        text,
        subText,
        notificationLevel: NotificationLevel.INFO,
    },
});

export const addWarnToast = (text: string, subText?: string): Action => ({
    type: LayoutActionTypes.AddToast,
    payload: {
        text,
        subText,
        notificationLevel: NotificationLevel.WARN,
    },
});
