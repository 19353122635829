export enum NotificationLevel {
    SUCCESS,
    INFO,
    WARN,
    ERROR,
}

export interface INotification {
    text: string;
    subText?: string;
    timeStamp: string;
    notificationLevel: NotificationLevel;
    id?: string;
}
