import { Basket as BasketTypes } from '~/models/basket';
import { addItemCategories, addOptions, ConverterOpts, getDictionaryValue } from '~/libs/ga4/utils';
import { GA4 } from '~/libs/ga4/types/ga4';

export function convertBasketItem(source: BasketTypes.IBasketItemDto, opts: ConverterOpts) {
    const target: GA4.TrackingProduct = {
        item_name: source?.name?.toLowerCase() as string,
        item_id: source?.itemId?.toLowerCase() as string,
        product_id: opts?.productId as string,
        item_brand: source?.brand?.name?.toLowerCase() as string,
        item_category4: 'n/a', // oe_match
        currency: opts?.currency,
        price: opts?.price,
    };

    if (typeof source?.itemId !== 'undefined' && typeof opts?.productId !== 'undefined') {
        const productItemNo = getDictionaryValue(source.itemId, 'productItemNo', opts.productId);
        if (typeof productItemNo !== 'undefined') {
            target.product_id = productItemNo;
        }
    }

    addOptions(target, opts);

    addItemCategories(source, target);

    return target;
}
