import { Products as ProductTypes } from '~/models/products';
import { GA4 } from '~/libs/ga4/types/ga4';
import { addOptions, getDictionaryValue, ConverterOpts } from '~/libs/ga4/utils';

export function convertProductTile(tile: ProductTypes.IProductTile, opts: ConverterOpts) {
    const { itemCategory = '', itemCategory2 = '', itemCategory3 = '' } = opts;

    const obj: GA4.TrackingProduct = {
        item_name: tile?.title?.toLowerCase() as string,
        item_id: tile?.itemNo?.toLowerCase() as string,
        item_brand: tile?.brand?.name?.toLowerCase() as string,
        currency: opts?.currency,
        price: opts?.price,
        item_category: itemCategory.toLowerCase(),
        item_category2: itemCategory2.toLowerCase(),
        item_category3: itemCategory3.toLowerCase(),
        item_category4: 'n/a', // oe_match
        item_category5: '', // stock_status
    };

    if (typeof tile?.itemNo !== 'undefined' && typeof tile?.productItemNo !== 'undefined') {
        const productItemNo = getDictionaryValue(tile.itemNo, 'productItemNo', tile.productItemNo);
        if (typeof productItemNo !== 'undefined') {
            obj.product_id = productItemNo;
        }
    }

    addOptions(obj, opts);

    return obj;
}
