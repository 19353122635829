import { captureException } from '@sentry/nextjs';
import { parse } from 'cookie';
import { getSession, signOut } from 'next-auth/react';
import { IMPERSONATE_COMPANY_SUFFIX, IMPERSONATE_USER_ID } from '~/constants/cookie';
import { isBrowser } from '~/helpers';
import { parseCookieValue } from '~/helpers/cookie.server.helper';

import type { Session } from 'next-auth';
import type { ICmsPageStatusCode } from '~/models/dev';

export type ApiClientErrorResponse<Response = Record<string, any>> = Response & {
    // status code
    cause: number;
    // sentry event id
    eventId: string;
};

export type RequestOptions = Omit<RequestInit, 'method'> & {
    returnEmptyOnAuthError?: boolean;
    returnEmptyOnNotFoundError?: boolean;
    // Use this when data should be resolved on 404s.
    // Useful for displaying the configured 404-page when requesting non-existent pages from the e.g. page endpoint
    returnResponseOnNotFoundError?: boolean;
};

export function isJSONResponse(headers: Headers) {
    const contentType = headers.get('content-type');
    if (!contentType) return;
    return RegExp(/application\/[^+]*[+]?(json);?.*/).exec(contentType);
}

function getImpersonationCookies() {
    if (!document?.cookie) {
        throw Error('getImpersonationCookies() is only allowed client side.');
    }

    const cookies = parse(document.cookie);

    const userIdCookie = parseCookieValue(
        cookies,
        IMPERSONATE_USER_ID, // the cookie name is encode to comply with js-cookie standards
    );

    const companySuffix = parseCookieValue(cookies, IMPERSONATE_COMPANY_SUFFIX);

    return { userId: userIdCookie?.id, companySuffix };
}

export class ApiClient {
    token: string | undefined | null;

    constructor(token?: string) {
        this.token = token || null;
    }

    createHeaders(session: Session | null, options?: RequestOptions) {
        const headers = new Headers(options?.headers);

        if (session?.accessToken) {
            headers.append('Authorization', `Bearer ${session.accessToken}`);
        }

        if (isBrowser && document?.cookie) {
            const { userId, companySuffix } = getImpersonationCookies();

            if (userId) {
                headers.append('X-FTZ-IMPERSONATE-USERID', userId);
            }

            if (companySuffix) {
                headers.append('X-FTZ-IMPERSONATE-COMPANYSUFFIX', companySuffix);
            }
        }

        return headers;
    }

    auth(auth: string | Session | null | undefined) {
        if (!auth) {
            throw new Error('No accessToken provided. This is needed for calling .auth()');
        }
        const accessToken = typeof auth === 'object' ? auth.accessToken : auth;
        return new ApiClient(accessToken);
    }

    parse(data: string, res: Response) {
        // Only try to PARSE as JSON if the headers indicate JSON type repsonse
        // This will avoid trying to parse each response.
        if (isJSONResponse(res.headers)) {
            try {
                return JSON.parse(data);
            } catch {
                return data;
            }
        }
        return data;
    }

    handleErrors(response: Response, data: any): ApiClientErrorResponse {
        let msg;

        switch (true) {
            case typeof data === 'string':
                msg = {
                    error: data,
                    statusCode: response.status,
                };
                break;
            case typeof data === 'object':
                msg = {
                    ...data,
                    statusCode: response.status,
                };
                break;
            default:
                msg = 'Unknown error occurred in API client.';
        }

        // Capture event in sentry, and give us a traceable id.
        const eventId = captureException(response);

        return {
            ...msg,
            cause: response.status,
            eventId: eventId,
        };
    }

    async request(url: string | URL, method: string, options?: RequestOptions, retry = 0): Promise<any> {
        const headers = this.createHeaders(null, options);

        if (this.token) {
            headers.append('Authorization', `Bearer ${this.token}`);

            // Avoid next request has token
            this.token = null;
        }

        const res = await fetch(url, { ...options, method, headers });
        const data = this.parse(await res.text(), res);

        if (!res.ok) {
            // 404 means that the resource was not found
            if (res.status === 404 && options?.returnEmptyOnNotFoundError) {
                // We want to send back an empty response from the server when we anticipate personalized data on the page.
                // Since client details aren't accessible on the server, we need to confirm them on the client side.
                return Promise.resolve({});
            }

            if (res.status === 404 && options?.returnResponseOnNotFoundError) {
                // We want to resolve the promise with the actual response on some 404 errors e.g. the page endpoint.
                // We do this to be able to show the configured 404 page from the CMS.
                return Promise.resolve(data);
            }

            // Both 401 and 403 are auth failures.
            // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status#client_error_responses
            if ([401, 403].includes(res.status)) {
                // When the server fetches a page that is returning an auth error, we want to render it empty
                // This option allows for that. But in the future it would be best to have a better contract
                // with the API so that we can define if we want to render the page with user data or not, but still sending our token.
                // This would also clean up all the .auth() calls, as we could inverse it.
                if (options?.returnEmptyOnAuthError) {
                    return Promise.resolve({});
                }

                // We got an auth error, but provided no token.
                // Most likely a issues on the client where the API wants an token, but we did not call .auth().
                if (!this.token) {
                    console.log('AUTH: The endpoint returns 401, but we have provided no token.', method, url);
                }

                if (retry < 1) {
                    // For some reason the request failed for a , we try to get the session and then the token.
                    // The request failed due to an auth error,
                    // We can try to fix this by refreshing the token.
                    const session = await getSession();
                    this.token = session?.accessToken;

                    return this.request(url, method, options, retry + 1);
                } else {
                    // The 403 reponse is when the server knows who the client is, but that person has no access.
                    // This could be you trying to enter a admin page while only being a user.
                    // We don't want to sign you out, but the request should be rejected.
                    if (res.status === 403) {
                        return Promise.reject(403);
                    }

                    // Seems like we are not logged in, or we have an issue with our login
                    // We force signout and go to the frontpage.
                    signOut({ redirect: false, callbackUrl: '/' });
                }
            }

            // Reject the promise with some enhanced information, and a tracking id from sentry.
            return Promise.reject(this.handleErrors(res, data));
        }

        if (Array.isArray(data)) {
            return data;
        }

        if (typeof data === 'object') {
            return {
                ...data,
                statusCode: res.status,
            };
        }

        return data;
    }

    get(url: string | URL, options?: RequestOptions) {
        return this.request(url, 'get', options);
    }

    post(url: string | URL, options?: RequestOptions) {
        return this.request(url, 'post', options);
    }

    postJSON(url: string | URL, JSONBody: object | [] | string, options?: RequestOptions) {
        return this.request(url, 'post', {
            ...options,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(JSONBody),
        });
    }

    put(url: string | URL, options?: RequestOptions) {
        return this.request(url, 'put', options);
    }

    putJSON(url: string | URL, JSONBody: object | [] | string, options?: RequestOptions) {
        return this.request(url, 'put', {
            ...options,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(JSONBody),
        });
    }

    delete(url: string | URL, options?: RequestOptions) {
        return this.request(url, 'delete', options);
    }

    deleteJSON(url: string | URL, JSONBody: object | [] | string, options?: RequestOptions) {
        return this.request(url, 'delete', {
            ...options,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(JSONBody),
        });
    }

    static instance() {
        return new ApiClient();
    }
}

export type IPage = ICmsPageStatusCode;
export type IErrorPage = Partial<IPage> & {
    statusCode: number;
    allowAnonymousUsers: boolean;
};
export const apiClient = ApiClient.instance();
