import { QueryCache, QueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Basket as BasketTypes } from '~/models/basket';
import { useNextDeliveryTime } from '~/services/basket/use-next-delivery-time';

// Will share data across pages and users (eg. DONT fetch user specific data)
export const sharedQueryClient = new QueryClient({
    defaultOptions: {
        queries: {},
    },
});

const isNextDeliveryTimeData = (data: any): data is BasketTypes.INextDeliveryDto => data.nextDeliveryLastCallTime !== undefined;

export const useInitQueryClient = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { setNextDeliveryTime } = useNextDeliveryTime();

    const [queryClientState] = useState(
        // This state ensures that data is not shared between different users and requests,
        // while still only creating the QueryClient once per component lifecycle.
        () => {
            const queryClient = new QueryClient({
                defaultOptions: {
                    queries: {
                        // Disabled since we don't rely on realtime data
                        refetchOnWindowFocus: false,
                        staleTime: 3000,
                    },
                },
                queryCache: new QueryCache({
                    onSuccess: (data) => {
                        if (!isNextDeliveryTimeData(data)) {
                            return;
                        }

                        if (!data.nextDeliveryLastCallTime) {
                            setNextDeliveryTime(null);
                        } else {
                            const lastCallTimeMs = new Date(data.nextDeliveryLastCallTime).getTime();
                            const todayMs = Date.now();
                            const time = lastCallTimeMs - todayMs + 250; // small threshold to make sure BE is up to date

                            setNextDeliveryTime(time);
                        }
                    },
                }),
            });

            return queryClient;
        }
    );

    return queryClientState;
};
