export function getInitials(name?: string) {
    if (!name) {
        return null;
    }

    if (name.length <= 3) {
        return name;
    }

    const names = name.split(' ');
    const firstInitial = names.shift()?.charAt(0);
    const remainingInitials = names[names.length - 1]?.substr(0, 2);

    if (remainingInitials) {
        return firstInitial + remainingInitials;
    }

    return firstInitial || null;
}
