/**
 * @description
 * Splits array into chunks with the same `size`
 */
export const chunkArray = <T>(arr: T[], size: number): T[][] =>
    arr.length > size ? [arr.slice(0, size), ...chunkArray(arr.slice(size), size)] : [arr];

/**
 * @description
 * Splits array into number of the `columns`
 */
export const splitArr = <T>(arr: T[], columns: number): T[][] => {
    const chunkSize = Math.ceil(arr.length / columns);

    return chunkArray(arr, chunkSize);
};
