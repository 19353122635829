import { memo } from 'react';
import Script from 'next/script';
import { useRouter } from 'next/router';

const isDevelopment = process.env.NODE_ENV === 'development';

function CookieConsentScript() {
    const culture = useCulture();

    if (isDevelopment) {
        // Cookieinformation can only be tested on whitelisted domains
        return null;
    }

    return (
        <Script
            id="CookieConsent"
            type="text/javascript"
            strategy="lazyOnload"
            data-culture={culture}
            src="//policy.app.cookieinformation.com/uc.js"
        />
    );
}

export const CookieConsent = memo(CookieConsentScript);

export function useCulture() {
    const { locale } = useRouter();

    // https://support.cookieinformation.com/en/articles/5451339-languages-and-texts
    switch (locale?.toLocaleLowerCase()) {
        case 'da-dk':
            return 'DA';
        case 'en-us':
        case 'en-gb':
            return 'EN';
        case 'de-de':
            return 'DE';
        default:
            return 'DA';
    }
}
