import { GA4 } from '~/libs/ga4/types/ga4';
import { addItemCategories, addOptions, ConverterOpts, getDictionaryValue } from '~/libs/ga4/utils';
import { Orders as OrderTypes } from '~/models/orders';

export function convertInvoiceItem(source: OrderTypes.IVehicleInvoiceDetailItem, opts: ConverterOpts): GA4.TrackingProduct {
    const target: GA4.TrackingProduct = {
        item_name: source?.title?.toLowerCase() as string,
        item_id: source?.itemId?.toLowerCase() as string,
        item_brand: source?.brand?.name?.toLowerCase() as string,
        item_category4: 'n/a', // oe_match
        currency: opts?.currency,
        price: opts?.price,
    };

    if (typeof source?.itemId !== 'undefined' && typeof opts?.productId !== 'undefined') {
        const productItemNo = getDictionaryValue(source.itemId, 'productItemNo', opts.productId);
        if (typeof productItemNo !== 'undefined') {
            target.product_id = productItemNo;
        }
    }

    addOptions(target, opts);

    addItemCategories(source, target);

    return target;
}
