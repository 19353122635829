export {
    GA4webVitals,
    GA4AddToCart,
    GA4removeFromCart,
    GA4BasketDelete,
    GA4BasketLoad,
    GA4BasketSave,
    GA4Download,
    GA4ExternalLink,
    GA4EmailLInk,
    GA4PhoneLink,
    GA4NewsletterSignup,
    GA4AddPreviousOrderToCart,
    GA4ProductInfoPlp,
    GA4ProductInfoTab,
    GA4PageNotFound,
    GA4PageView,
    GA4ReportError,
    GA4CarInfoAdditional,
    GA4LatestCars,
    GA4CarInfoShowMore,
    GA4SeePreviousOrder,
    GA4FilterPlp,
    GA4Login,
    GA4AddListToCart,
    GA4SelectItem,
    GA4SearchFullSearch,
    GA4ViewListItems,
    GA4SearchMiniSearch,
    GA4SearchMiniSearchClick,
    GA4ViewItem,
    GA4ModifyWishlist,
    GA4BeginCheckout,
    GA4CompleteCheckout,
    GA4RemoveListFromCart,
    GA4ExtendPurchaseCall,
    GA4SisterCategoryClicked,
    GA4CampaignClicked,
} from './ga4';
