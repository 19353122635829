import React, { createContext, useContext, useState } from 'react';

export type NextDeliveryTimeContextValue = {
    setNextDeliveryTime: (time: number | null) => void;
    nextDeliveryTime: number | null;
};

export const NextDeliveryTimeContext = createContext<NextDeliveryTimeContextValue | null>(null);

export type NextDeliveryTimeProviderProps = {
    children?: React.ReactNode | React.ReactNode[];
};

export const NextDeliveryTimeProvider = ({ children }: NextDeliveryTimeProviderProps) => {
    const [nextDeliveryTime, setNextDeliveryTime] = useState<number | null>(null);

    return <NextDeliveryTimeContext.Provider value={{ setNextDeliveryTime, nextDeliveryTime }}>{children}</NextDeliveryTimeContext.Provider>;
};

export const useNextDeliveryTime = () => useContext(NextDeliveryTimeContext);
