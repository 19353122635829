import { parse, serialize, CookieSerializeOptions } from 'cookie';
import { NextApiResponse, NextApiRequest } from 'next';
import { IMPERSONATE_COMPANY_SUFFIX, IMPERSONATE_USER_ID } from '~/constants/cookie';

export function convertToCookieObject(value: unknown) {
    return typeof value === 'object' ? `j:${JSON.stringify(value)}` : String(value);
}

// To delete a cookie in the browser it has to have the same options as the cookie you delete
export const deleteCookie = (res: NextApiResponse, name: string, options: CookieSerializeOptions = {}) =>
    res.setHeader(
        'Set-Cookie',
        serialize(name, '', {
            expires: new Date(0),
            maxAge: 0,
            ...options,
        })
    );

export const readCookie = (req: NextApiRequest, name: string) => {
    const cookies = parse(req.headers.cookie || '');
    return parseCookieValue(cookies, name);
};

export const COOKIE_JSON_REGEX = /^j:+/;

export const parseCookieValue = (cookies: Partial<{ [key: string]: string }>, name: string) => {
    let cookie = cookies[name];
    if (!cookie) {
        cookie = cookies[encodeURIComponent(name)];
    }

    if (!cookie) {
        return false;
    }

    if (cookie.match(COOKIE_JSON_REGEX)) {
        return JSON.parse(cookie.replace('j:', ''));
    }

    return cookie;
};

export function deleteImpersonateCookie(res: NextApiResponse) {
    const options = {
        expires: new Date(0),
        maxAge: 0,
        path: '/',
    };

    // Delete cookie if request doesn't have access to impersonation
    res.setHeader('Set-Cookie', [
        serialize(encodeURIComponent(IMPERSONATE_USER_ID), '', options),
        serialize(encodeURIComponent(IMPERSONATE_COMPANY_SUFFIX), '', options),
    ]);
}
